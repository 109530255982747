.bg-0 {
    background-color: #ffffff;
    color: #0e3d8a;
}

.bg-1 {
    background-color: #0e3d8a;
    color: #0e3d8a;
}

.ant-descriptions-item-label {
    color: #5c6b77;
}

.ant-descriptions-item {
    padding-bottom: 0px !important;
}

.ant-descriptions-item-content {
    margin-bottom: 8px;
}

.content {
    min-height: calc(100vh - 150px - 72px);
}